import { Box, Flex, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getDistanceFromDate } from "src/helpers/formatDate";
import JobOffer from "src/types/JobOffer.type";
import { DateTag } from "./badges/DateTag";
import Rate from "./Rate";
import Config from "../classes/application/Config";

interface Props {
  jobOffer: Partial<JobOffer>;
}

const JobHeader = (props: Props) => {
  const { jobOffer: j } = props;
  const { t } = useTranslation();

  return (
    <Box mb="md" w="100%" maw={1000}>
      <Flex
        direction="column"
        justify="start"
        align="left"
        style={{ borderBottom: "1px solid" }}
      >
        <Title order={2} lineClamp={1} c="primary">
          {j.title}
        </Title>
      </Flex>
      <Flex direction="row" justify="space-between" mt="md">
        <Flex direction="column">
          {j.meta_created_at && (
            <Text fw={300} size="sm" c="dimmed">
              {`${t("posted")} ${getDistanceFromDate({
                from: j.meta_created_at,locale:Config.getInstance().getLanguage()
              })}`}
            </Text>
          )}
          <DateTag date={j.dateFrom} />
        </Flex>
        <Box>
          <Title order={5} my="md">
            {t("hourly_rate") + ":"}
          </Title>
          <Title m="md" order={4}>
            <Rate rate={j.hourlyRate} costsIncluded={j.costsIncluded} />
          </Title>
        </Box>
      </Flex>
    </Box>
  );
};

export default JobHeader;
