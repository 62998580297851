import { createBrowserRouter } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Home from "src/pages/Home";
import Error from "src/pages/Error";
import appRoutes from "./app.routes";
import CompanySlug from "src/pages/CompanySlug";
import Register from "src/components/Register";
import RootLayout from "src/pages/Root.Layout";

// Lazy load Chatbot module
const ChatbotLayout = lazy(() => import("src/modules/chatbot/ChatbotLayout"));
const Chatbot = lazy(() => import("src/modules/chatbot/Chatbot"));

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error showBackToHome />,
    children: [
      // authenticated routes
      {
        index: true,
        element: <Home />,
      },
      appRoutes,
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "c/:slug",
        element: <CompanySlug />,
      },
      {
        path: "chatbot",
        element: (
            <Suspense fallback={<div>Loading Chatbot...</div>}>
              <ChatbotLayout />
            </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
                <Suspense fallback={<div>Loading Chatbot...</div>}>
                  <Chatbot />
                </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

export default routes;
