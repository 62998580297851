import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToApp() {
  const navigate = useNavigate();
  useEffect(() => {
  //alert("redir");
    navigate("/app");
  });
  return <Fragment />;
}
