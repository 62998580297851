import {useTranslation} from "react-i18next";
import _map from "lodash/map";
import {Flex, Paper, Title} from "@mantine/core";
import ActionButton from "./ActionButton";
import useIsMobile from "src/hooks/useIsMobile";
import Confirm from "./ui/widget/Confirm/Confirm";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import ConfirmHandler from "../classes/ui/ConfirmHandler";
import InfoBox from "./ui/widget/InfoBox/InfoBox";

interface Action {
    to?: string;
    label?: string;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void; // Make event optional
    variant?: string;
    color?: string;
    disabled?: boolean;
    Icon?: React.FC;
    tooltip?: string;
    cost?: number;
    hasConfirm?: boolean;
    confirmTitle?: string;
    confirmMessage?: string;
}

interface Props {
    title?: string;
    center?: boolean;
    isReady?: boolean;
    actions?: (Action | null)[];
    hasConfirm?: boolean;
    confirmTitle?: string;
    confirmMessage?: string;
    infoboxCode?: string;
}

const Actions = (props: Props) => {
    const navigate = useNavigate();

    const {t} = useTranslation();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    const {actions = [], title = "", center, isReady = true} = props;

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmTo, setConfirmTo] = useState("");
    const [confirmTitle, setConfirmTitle] = useState<string>("");
    const [confirmMessage, setConfirmMessage] = useState<string>("");

    if (false) {
        setConfirmTo("");
    }
    let infoboxcode = props.infoboxCode;

    const style = {
        background: title
            ? "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)"
            : "transparent",
        boxShadow: "none",
        border: "none",
    };

    const handleConfirm = () => {
        const confhdl = ConfirmHandler.getInstance();
        if (confhdl) {
            const subh = confhdl.getSubmitHandler();
            if (subh) {
                confhdl.executeSubmitHandler();
            }
        }
        if (confirmTo) {
            navigate(confirmTo);
        }
        setIsConfirmOpen(false); // Close confirmation modal
    };

    const handleCancel = () => {
        setIsConfirmOpen(false);
    };

    const openConfirm = (submitHandler: any) => {
        setIsConfirmOpen(true);
        ConfirmHandler.getInstance().setSubmitHandler(submitHandler);
    };

    return (
        <Paper c="white" px="md" py="xs" mb="xl" style={style}>
            {/* Confirm Modal */}
            <Confirm
                isOpen={isConfirmOpen}
                title={confirmTitle}
                message={confirmMessage}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />

            {/* InfoBox Component */}

            <Flex justify="space-between" align="center" direction={direction}>
                <Flex direction="row" align="center" my="md" gap="xs">
                    {/* Trigger InfoBox Modal */}

                    {typeof infoboxcode === "string" && (
                        <InfoBox
                            code={infoboxcode}
                            title=""
                            content=""
                        />
                    )}

                    <Title order={4} tt="uppercase">
                        {t(title)}
                    </Title>
                </Flex>

                <Flex
                    direction={direction}
                    justify={center || isMobile ? "center" : "end"}
                    gap="sm"
                    wrap="wrap"
                >
                    {_map(actions, (action) => {
                        if (!action) return null;
                        const {
                            to = "/",
                            label,
                            variant = "primary",
                            onClick,
                            color,
                            disabled = false,
                            Icon = undefined,
                            tooltip,
                            cost = 0,
                            hasConfirm,
                            confirmTitle,
                            confirmMessage,
                        } = action;

                        let handleClick = onClick;
                        if (hasConfirm && isReady) {
                            handleClick = () => {
                                if (confirmTitle) setConfirmTitle(confirmTitle);
                                if (confirmMessage) setConfirmMessage(confirmMessage);
                                openConfirm(onClick);
                            };
                        }

                        return (
                            <ActionButton
                                key={to + label}
                                to={to}
                                label={label}
                                variant={variant}
                                onClick={handleClick}
                                color={color}
                                disabled={disabled}
                                Icon={Icon}
                                tooltip={tooltip}
                                cost={cost}
                                isReady={isReady}
                            />
                        );
                    })}
                </Flex>
            </Flex>
        </Paper>
    );
};

export default Actions;
