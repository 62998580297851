import {ArrayAccessor} from "../ArrayAccessor";

export class ServiceResponse extends ArrayAccessor{
    protected _statusCode: number|null;
    protected _message: string|null;
 //   protected _data: Record<string, any> | null;
    protected _errors: { key: string; value: string }[];

    constructor(
        statusCode?: number,
        message?: string,
        data?: Record<string, any>,
        errors?: { key: string; value: string }[]
    ) {
        super()
        this._statusCode = statusCode||null;
        this._message = message||null;
     //   this._data = data || null;
        this._errors = errors || [];
    }

    getStatusCode(): number|null {
        return this._statusCode;
    }

    setStatusCode(value: number): void {
        if (value < 100 || value > 599) {
            throw new Error("Invalid HTTP status code");
        }
        this._statusCode = value;
    }

    getMessage(): string|null {
        return this._message;
    }

    setMessage(value: string): void {
        if (!value || typeof value !== "string") {
            throw new Error("Message must be a non-empty string");
        }
        this._message = value;
    }

    /*
    getData(): Record<string, any> | null {
        return this._data;
    }*/

    /*
    setData(value: Record<string, any> | null): void {


        this._data = value;
    }
    */

    getErrors(): { key: string; value: string }[] {
        return this._errors;
    }

    setErrors(errors: { key: string; value: string }[]): void {
        this._errors = errors;
    }

    addError(key: string, value: string): void {
        this._errors.push({ key, value });
    }

    toJson(): Record<string, any> {
        return {
            status: this._statusCode,
            message: this._message,
            ...(this._data && { data: this._data }),
            ...(this._errors.length > 0 && { errors: this._errors }),
        };
    }
}
