import { modals } from "@mantine/modals";
import { translate } from "./initLanguage";

function showReloadModal(title: string, desc: string) {
  modals.closeAll();

  localStorage.setItem("has_token_err","1");
  //window.location.reload(true);
  setTimeout(function () {
        modals.openContextModal({
          modal: "notification",
          title: translate(title),
          trapFocus: true,
          closeOnEscape: false,
          closeOnClickOutside: false,
          withCloseButton: false,
          innerProps: {
            modalBody: translate(desc),
            onClose: () => {
              window.location.reload();
            },
          },
        });
      }
  ,3000
  )
}

export default showReloadModal;
