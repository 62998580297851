import _get from "lodash/get";
import _toUpper from "lodash/toUpper";
import {CroppedList} from "src/components/badges/CroppedList";
import CountryIcon from "./CountryIcon";
import Pill from "./Pill";
import countyDict from "src/constants/countries";

interface Props {
    countries: string[] | undefined;
    big?: boolean;
}

const CountryList = ({countries = [], big = false}: Props) => {
    const cap = big ? null : 3;

    return (
        <CroppedList cap={cap}>
            {countries.map((c) => {
                if (big) {
                    const countryLabel = _get(countyDict, _toUpper(c), c);
                    return (
                        <Pill
                            Icon={() => <CountryIcon id={c} key={c}/>}
                            label={countryLabel}
                            key={c}
                        />
                    );
                }
                return <CountryIcon id={c} key={c}/>;
            })}
        </CroppedList>
    );
};

export default CountryList;
