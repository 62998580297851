import Loader from "src/components/Loader";
import ApplicationsTable from "src/components/tables/ApplicationsTable";
import Actions from "src/components/Actions";
import {useGET} from "src/hooks/useApi";
import Application from "src/types/Application.type";

const ListApplicationReceived = () => {
    const {data, isLoading, error} = useGET<Application[]>(
        "account/company/applications_received"
    );

    return (
        <Loader isLoading={isLoading} error={error} hasData={!!data}>
            <Actions
                title="cvs_received"
                infoboxCode="infobox_cvs_received"
            />
            <ApplicationsTable data={data}/>
        </Loader>
    );
};

export default ListApplicationReceived;
