import {ArrayAccessor} from "../lib/ArrayAccessor";

export class JobOfferModel extends ArrayAccessor {
    public get_id(): string {
        return this.get("id");
    }

    public set_id(id: string): void {
        this.set("id", id);
    }

    public get_title(): string {
        return this.get("title");
    }

    public set_title(title: string): void {
        this.set("title", title);
    }

    public get_desc(): string {
        return this.get("desc");
    }

    public set_desc(desc: string): void {
        this.set("desc", desc);
    }

    public get_meta_created_at(): string {
        return this.get("meta_created_at");
    }

    public set_meta_created_at(meta_created_at: string): void {
        this.set("meta_created_at", meta_created_at);
    }

    public get_meta_updated_at(): string {
        return this.get("meta_updated_at");
    }

    public set_meta_updated_at(meta_updated_at: string): void {
        this.set("meta_updated_at", meta_updated_at);
    }

    public get_dateFrom(): string {
        return this.get("dateFrom");
    }

    public set_dateFrom(dateFrom: string): void {
        this.set("dateFrom", dateFrom);
    }

    public get_duration(): number {
        return this.get("duration");
    }

    public set_duration(duration: number): void {
        this.set("duration", duration);
    }

    public get_jobTitle(): string | undefined {
        return this.get("jobTitle");
    }

    public set_jobTitle(jobTitle?: string): void {
        this.set("jobTitle", jobTitle);
    }

    public get_zones(): string[] {
        return this.get("zones");
    }

    public set_zones(zones: string[]): void {
        this.set("zones", zones);
    }

    public get_languages(): string[] {
        return this.get("languages");
    }

    public set_languages(languages: string[]): void {
        this.set("languages", languages);
    }

    public get_skills(): string[] {
        return this.get("skills");
    }

    public set_skills(skills: string[]): void {
        this.set("skills", skills);
    }

    public get_companyId(): string {
        return this.get("companyId");
    }

    public set_companyId(companyId: string): void {
        this.set("companyId", companyId);
    }

    public get_expiryDate(): string {
        return this.get("expiryDate");
    }

    public set_expiryDate(expiryDate: string): void {
        this.set("expiryDate", expiryDate);
    }

    /*
    public get_level(): Seniority {
        return this.get("level");
    }

    public set_level(level: Seniority): void {
        this.set("level", level);
    }
    */

    /*
    public get_status(): JobOfferStatus {
        return this.get("status");
    }

    public set_status(status: JobOfferStatus): void {
        this.set("status", status);
    }
    */

    public get_acceptedApplicationId(): string | undefined {
        return this.get("acceptedApplicationId");
    }

    public set_acceptedApplicationId(acceptedApplicationId?: string): void {
        this.set("acceptedApplicationId", acceptedApplicationId);
    }

    public get_applicationId(): string | undefined {
        return this.get("applicationId");
    }

    public set_applicationId(applicationId?: string): void {
        this.set("applicationId", applicationId);
    }

    public get_certifications(): string[] {
        return this.get("certifications");
    }

    public set_certifications(certifications: string[]): void {
        this.set("certifications", certifications);
    }

    public get_education(): string[] {
        return this.get("education");
    }

    public set_education(education: string[]): void {
        this.set("education", education);
    }

    public get_fields(): string[] {
        return this.get("fields");
    }

    public set_fields(fields: string[]): void {
        this.set("fields", fields);
    }

    public get_hourlyRate(): number {
        return this.get("hourlyRate");
    }

    public set_hourlyRate(hourlyRate: number): void {
        this.set("hourlyRate", hourlyRate);
    }

    public get_costsIncluded(): boolean | undefined {
        return this.get("costsIncluded");
    }

    public set_costsIncluded(costsIncluded?: boolean): void {
        this.set("costsIncluded", costsIncluded);
    }

    public get_nationality(): string[] | undefined {
        return this.get("nationality");
    }

    public set_nationality(nationality?: string[]): void {
        this.set("nationality", nationality);
    }

    public get_reviewId(): string | undefined {
        return this.get("reviewId");
    }

    public set_reviewId(reviewId?: string): void {
        this.set("reviewId", reviewId);
    }

    public get_training(): string[] {
        return this.get("training");
    }

    public set_training(training: string[]): void {
        this.set("training", training);
    }

    public get_visa(): string[] | undefined {
        return this.get("visa");
    }

    public set_visa(visa?: string[]): void {
        this.set("visa", visa);
    }

    /*
    public get_Company(): Company {
        return this.get("Company");
    }

    public set_Company(company: Company): void {
        this.set("Company", company);
    }

    public get_Review(): Review | undefined {
        return this.get("Review");
    }

    public set_Review(review?: Review): void {
        this.set("Review", review);
    }

    public get_Applications(): Application[] {
        return this.get("Applications");
    }

    public set_Applications(applications: Application[]): void {
        this.set("Applications", applications);
    }

    public get_Proposal(): Proposal[] {
        return this.get("Proposal");
    }

    public set_Proposal(proposal: Proposal[]): void {
        this.set("Proposal", proposal);
    }
     */
}
