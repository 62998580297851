import {ServiceResponse} from "./ServiceResponse";

export abstract class ApiService {
    protected _apiUrl: string;

    protected constructor(apiUrl: string) {
        this._apiUrl = apiUrl;
    }

    abstract get(endpoint: string): Promise<ServiceResponse>;

    abstract put(endpoint: string, data: any, response?: ServiceResponse): Promise<any>;

    abstract post(endpoint: string, data: any, response?: ServiceResponse): Promise<any>;

    public async readHttpStream(
        url: string,
        options?: RequestInit,
        onRead?: (chunk: string) => void,
        onError?: (error: any) => void,
        onComplete?: () => void
    ): Promise<void> {

    }

    /*
    abstract readHttpStreamx(
        url: string,
        options?: RequestInit,
        onRead?: (chunk: string) => void,
        onError?: (error: any) => void,
        onComplete?: () => void
    ): AsyncGenerator<string>;
    abstract readHttpStream(url: string, options?: RequestInit): AsyncGenerator<string>;
*/
}
