import React from 'react';

interface InfoBoxIconProps {
    size?: number;
    color?: string;
    onClick?: () => void;
}

const InfoBoxIcon: React.FC<InfoBoxIconProps> = ({size = 42, color = "#2196F3", onClick}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={{display: "block", width: `${size}px`, height: `${size}px`, color: color}}
            onClick={onClick} // Attach the onClick handler
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
};

export default InfoBoxIcon;

