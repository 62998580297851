import Error from "src/pages/Error";
import Dashboard from "src/pages/Dashboard";
import Account from "src/pages/Account";
import Settings from "src/pages/Settings";
import Support from "src/pages/Support";
import crudRoutes from "./crud.routes";
import NotificationList from "src/pages/NotificationList";
import NotificationView from "src/pages/NotificationView";
import Credits from "src/pages/Credits";
import Invite from "src/pages/Invite";
import MainLayout from "src/pages/Main.Layout";
import Activity from "src/pages/Activity";
import sectionRoutes from "./section.routes";
import Tutorial from "src/pages/Tutorial";
import TutorialsList from "src/pages/TutorialsList";
import Reset from "../pages/Reset";
import ApiCfg from "../pages/testconfig/ApiCfg";
import AiCfg from "../pages/testconfig/AiCfg";
import InfoBoxesCfg from "../pages/testconfig/InfoBoxesCfg";
import TestCfg from "../pages/testconfig/TestCfg";

const appRoutes = {
    path: "app",
    element: <MainLayout/>,
    errorElement: <Error showBackToHome/>,
    children: [
        {
            index: true,
            element: <Dashboard/>,
        },
        ...sectionRoutes,
        {
            path: "activity",
            element: <Activity/>,
        },
        ...crudRoutes,
        // account pages
        {
            path: "account",
            element: <Account/>,
        },
        {
            path: "settings",
            element: <Settings/>,
        },
        {
            path: "tutorial",
            children: [
                {
                    index: true,
                    element: <TutorialsList/>,
                },
                {
                    path: ":tutorialId",
                    element: <Tutorial/>,
                },
            ],
        },
        {
            path: "support",
            element: <Support/>,
        },
        {
            path: "credits",
            element: <Credits/>,
        },
        {
            path: "invite",
            element: <Invite/>,
        },
        {
            path: "notifications",
            element: <NotificationList/>,
            children: [
                {
                    path: ":notificationId",
                    element: <NotificationView/>,
                },
            ],
        },
        {
            path: "reset",
            element: <Reset/>,
        },
        {
            // path for experimental configurations
            path: "ik4kf884ax9",
            element: <ApiCfg/>,
        },
        {
            // path for experimental configurations
            path: "ik4kf884ax91",
            element: <InfoBoxesCfg/>,
        },
        {
            //Daniele_1_ai
            path: "ik4kf884ax91aic",
            element: <AiCfg/>,
        },
        {
            //Daniele_1_ai
            path: "ik4kf884ax91aicf",
            element: <TestCfg/>,
        },
    ],
};

export default appRoutes;
