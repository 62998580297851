import _map from "lodash/map";
import _toLower from "lodash/toLower";
import {Menu, UnstyledButton} from "@mantine/core";
import i18n from "src/helpers/initLanguage";
import supportedLocales from "src/constants/supportedLocales.const";
import useSettings from "src/hooks/useSetting";
import {IconCheck} from "@tabler/icons-react";
import {useState} from "react";
import CountryIcon from "./badges/CountryIcon";

function LangSwitch() {
    const {save} = useSettings();

    const [loc, setLoc] = useState(i18n.language);

    const updateLocale = (locale: string | null) => {
        if (!locale) {
            return;
        }
        const l = _toLower(locale.substring(0, 2));
        i18n.changeLanguage(locale);
        setLoc(l);
        save("settings_language", l);
    };

    return (
        <Menu shadow="md" width={200}
              styles={{
                  dropdown: {zIndex: 9999}, // Ensure high z-index for the dropdown
              }}
        >
            <Menu.Target>
                <UnstyledButton h={34}>
                    <CountryIcon id={loc} size={10} noTooltip/>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Language</Menu.Label>
                {_map(supportedLocales, (l) => (
                    <Menu.Item
                        key={l.value}
                        onClick={() => updateLocale(l.value)}
                        rightSection={l.value === loc ? <IconCheck/> : null}
                        style={{
                            color: "black"
                        }}
                    >
                        {l.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
}

export default LangSwitch;
