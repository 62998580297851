import {
  Box,
  Button,
  Flex,
  Group,
  Slider,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { SearchFilters } from "src/types/SearchFilters.type";
import CustomSelect from "../CustomSelect";
import SkillSelect from "../SkillSelect";
import Collapse from "../Collapse";
import { ReactNode } from "react";

interface Props {
  setData: (e: SearchFilters) => void;
  mode?: "employee" | "jobOffer";
}
const now = new Date();

const Column = ({ children }: { children: ReactNode }) => (
  <Flex m="sm" direction="column" w={300}>
    {children}
  </Flex>
);

const SearchFilter = ({ setData, mode = "employee" }: Props) => {
  const form = useForm<SearchFilters>({
    initialValues: {
      from: now,
      ignoreAvailability: true,
    },
    transformValues: (values) => {
      return {
        ...values,
        from: values.ignoreAvailability ? null : values.from,
      };
    },
  });
  const submit = () => {
    const data = form.getTransformedValues();
    form.resetDirty();
    setData(data);
  };
  const { t } = useTranslation();
  const reset = () => {
    form.reset();
    setData({});
    form.setFieldValue("ignoreAvailability", true);
  };
  const title = (
    <Title order={4} tt={"uppercase"}>
      {t("filters")}
    </Title>
  );

  //<Switch {...form.getInputProps("ignoreAvailability")} />
  //
  return (
    <Collapse title={title} withBg isOpen>
      <Flex direction="row" justify="center" wrap="wrap" gap="sx">
        <Column>
          <CustomSelect
            label="job_title"
            source="roles"
            inputProps={form.getInputProps("jobTitle")}
          />

          <CustomSelect
            label="level"
            source="levels"
            inputProps={form.getInputProps("level")}
          />

          <DateInput
            my="md"
            size="sm"
            label={t("available_from")}
            disabled={form.values?.ignoreAvailability}
            minDate={now}
            defaultDate={now}
            valueFormat="DD/MM/YYYY"
            {...form.getInputProps("from")}
          />
          <Group my="sm">
            <Switch
                checked={form.values.ignoreAvailability}
                onChange={form.getInputProps("ignoreAvailability").onChange}
                onBlur={form.getInputProps("ignoreAvailability").onBlur}
                name="ignoreAvailability"
                id="ignoreAvailability"
            />
            <Text size="sm">{t("ignoreAvailability")}</Text>
          </Group>
        </Column>
        <Column>
          <CustomSelect
            source="fields"
            inputProps={form.getInputProps("fields")}
          />

          <CustomSelect
            label="languages"
            source="languages"
            inputProps={form.getInputProps("languages")}
          />

          <CustomSelect
            source="zones"
            inputProps={form.getInputProps("zones")}
          />
        </Column>

        <Column>
          <Box my="lg">
            <SkillSelect
              values={form.values.skills ?? []}
              setValues={(v) => {
                form.setFieldValue("skills", v);
              }}
              asSelect
            />
          </Box>
          <Title order={6} fw={500} mt="md">
            {t("hourly_rate_max")}
          </Title>
          <Slider
            my="md"
            min={0}
            max={100}
            step={25}
            defaultValue={0}
            marks={[
              { value: 0, label: "no filter" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 75, label: "75" },
              { value: 100, label: "100" },
            ]}
            {...form.getInputProps("hourlyRate")}
          />
          {mode === "jobOffer" && (
            <>
              <Title order={6} fw={500} mt="md">
                {t("max_duration_days")}
              </Title>
              <Slider
                my="md"
                min={0}
                max={400}
                step={1}
                defaultValue={0}
                marks={[
                  { value: 30, label: t("one_month") },
                  { value: 180, label: t("six_months") },
                  { value: 360, label: t("one_year") },
                ]}
                {...form.getInputProps("duration")}
              />
            </>
          )}
        </Column>
      </Flex>
      <Flex justify="end" mt="lg" gap="xs">
        <Button onClick={reset} variant="default">
          {t("reset")}
        </Button>
        <Button onClick={submit} disabled={!form.isDirty()}>
          {t("apply")}
        </Button>
      </Flex>
    </Collapse>
  );
};

export default SearchFilter;
