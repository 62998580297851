import Company from "src/types/Company.type";
import SimpleTable from "./SimpleTable";
import MatchTableRow from "./rows/MatchesTable.Row";

interface Props {
  data?: (Partial<Company> & { id: string })[];
}


const cols = ["", "name", "vat", "type", "size", "contacts"];
function MatchesTable({ data = [] }: Props) {
  return (
  <>
      <SimpleTable

        section="match"
        cols={cols}
        rows={data}
        Row={MatchTableRow}
        readOnly

      />
  </>
  );
}

export default MatchesTable;
