import {ServiceResponse} from "../../lib/http/ServiceResponse.js";

class InfoboxHandler {
    private static instance: InfoboxHandler | null = null;
    protected _title: string = "";
    protected _content: string = "";
    protected _data: any[];
    protected _has_title: boolean;

    public getData(): any[] {
        return this._data;
    }

    private constructor() {
        this._has_title = false;
        this._data = [];
    }

    public fillFromResponse(response: ServiceResponse) {
        this._has_title = false;
        this.setTitle("");
        this.setContent("");

        try {
            let servicedata = response.getData();
            if (servicedata) {
                let infoboxdata: any = servicedata.data.infobox;
                this.setContent(infoboxdata.content);

                if (infoboxdata.data) {
                    this._data = infoboxdata.data;
                }
                if (infoboxdata.title) {
                    this._has_title = true;
                    this.setTitle(infoboxdata.title);
                }
            }
        } catch (e) {

        }
    }

    public static getInstance(): InfoboxHandler {
        if (!InfoboxHandler.instance) {
            InfoboxHandler.instance = new InfoboxHandler();
        }
        return InfoboxHandler.instance;
    }

    public getTitle(): string {

        return this._title;
    }

    public setTitle(value: string) {
        this._title = value;
    }

    public getContent(): string {
        return this._content;
    }

    public setContent(value: string) {
        this._content = value;
    }
}

export default InfoboxHandler;
