import {ApiService} from "../ApiService.js";
import {ServiceResponse} from "../ServiceResponse";

export class ApiServiceXmlHttp extends ApiService {
    constructor(apiUrl: string) {
        super(apiUrl);
    }


    test() {

        console.log("testing");
    }

    /**
     * Reads a stream of strings from an HTTP connection.
     * @param url - The endpoint URL for the HTTP connection.
     * @param options - Optional fetch configuration (e.g., headers, method).
     * @param onRead - A callback function to handle each read chunk.
     * @param onError - A callback function to handle any errors.
     * @param onComplete - A callback function to handle the completion of the stream.
     * @returns A promise that resolves when the stream is fully processed.
     */
    public async readHttpStream(
        url: string,
        options?: RequestInit,
        onRead?: (chunk: string) => void,
        onError?: (error: any) => void,
        onComplete?: () => void
    ): Promise<void> {
        console.log("START FEEEEEEEEEEEE");
        const response = await fetch(`${this._apiUrl}${url}`, options);

        if (!response.ok) {
            const error = new Error(`Failed to fetch stream. Status: ${response.status}`);
            if (onError) onError(error);
            throw error;
        }

        const reader = response.body?.getReader();

        if (!reader) {
            const error = new Error("The HTTP response does not contain a readable body.");
            if (onError) onError(error);
            throw error;
        }

        const decoder = new TextDecoder(); // Decodes UTF-8 chunks into strings

        try {
            // Read the stream
            let chunkData = '';
            while (true) {
                const {value, done} = await reader.read();
                if (done) break; // End of the stream

                if (value) {
                    const chunk = decoder.decode(value, {stream: true});
                    chunkData += chunk; // Append the chunk to chunkData

                    // If there's a read callback, pass the chunk to it
                    if (onRead) onRead(chunk);
                }
            }

            // Optionally, process the entire stream data at once
            if (onRead && chunkData) {
                onRead(chunkData);
            }

        } catch (err) {
            console.error("Error while reading HTTP stream:", err);
            if (onError) onError(err); // Handle error using the provided handler
            throw err;
        } finally {
            // Call onComplete when done
            if (onComplete) onComplete();
            reader.releaseLock();
        }
    }

    public async readStringStream(stream: ReadableStream<string>): Promise<void> {
    }


    public post(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("POST", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");

            let sr = new ServiceResponse();
            if (response) {
                sr = response;
            }

            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200 || xhr.status === 201) {
                    sr.setData(JSON.parse(xhr.responseText));
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            // Send the request with the data in the body
            xhr.send(JSON.stringify(data));
        });
    }

    public get(endpoint: string): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("GET", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");

            let sr = new ServiceResponse();

            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200) {
                    sr.setData(JSON.parse(xhr.responseText));
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            xhr.send();
        });
    }

    public put(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("PUT", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");

            let sr = new ServiceResponse();
            if (response) {
                sr = response;
            }
            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200) {
                    sr.setData(JSON.parse(xhr.responseText));
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            // Send the request with the data in the body
            xhr.send(JSON.stringify(data));
        });
    }

}
