import React, { useState } from 'react';
import Application from "../../classes/Application";
import { useLocation } from "react-router-dom";

export default function InfoBoxesCfg() {
    const cfg = Application.getInstance().getConfig();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [enabled, setEnabled] = useState<string | null>(() => {
        const localStorageValue = localStorage.getItem("infoboxes");
        if (localStorageValue === "true") {
            return "true";
        }
        return queryParams.get("enabled");
    });

    if (enabled === "true") {
        localStorage.setItem("infoboxes", "true");
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const newEnabled = isChecked ? "true" : "false";
        localStorage.setItem("infoboxes", newEnabled);
        setEnabled(newEnabled); // Update state to force a re-render
    };

    const handleReset = () => {
        window.location.href = "/app";
    };

    return (
        <div>
            <h2>Infoboxes</h2>
            <input
                type="checkbox"
                checked={enabled === "true"}
                onChange={handleChange}
            />
        </div>
    );
}
