import { Divider, Flex, Grid, GridCol, Text, Title, TitleOrder } from "@mantine/core";
import { ReactNode } from "react";
import useIsMobile from "src/hooks/useIsMobile";
import "./EmployeeRow.css"; // Import the CSS file

interface Props {
    children: ReactNode;
    title?: ReactNode;
    desc?: string;
    order?: TitleOrder;
}

export const EmployeeRow = ({ children, title, desc, order = 4 }: Props) => {
    const isMobile = useIsMobile();
    const cols = isMobile ? 1 : 12;

    return (
        <div className="employee-row">
            <Grid align="stretch" columns={cols}>
                {(title || desc) && (
                    <GridCol span={12}>
                        <Flex direction="column" align="center" mb="md">
                            {title && (
                                <Title order={order} className="employee-row-title">
                                    {title}
                                </Title>
                            )}
                            {desc && <Text size="sm" className="employee-row-desc">{desc}</Text>}
                        </Flex>
                    </GridCol>
                )}
                <GridCol span={12}>
                    <Flex
                        wrap="wrap"
                        direction="row"
                        justify={isMobile ? "center" : "flex-start"}
                        className="employee-row-content"
                    >
                        {children}
                    </Flex>
                </GridCol>
            </Grid>
            <Divider my="md" />
        </div>
    );
};

