import _isEmpty from "lodash/isEmpty";
import {EmployeeRow} from "../../../components/EmployeeRow";
import Actions from "src/components/Actions";
import Employee from "../../../../types/Employee.type";
import NotFound from "../../../../components/NotFound";
import FieldList from "../../../../components/badges/FieldList";
import SkillSelect from "../../../../components/SkillSelect";
import {JobOfferStatus as JobOfferStatusType} from "src/types/JobOfferStatus.type";
import JobOfferStatusPill from "../../../../components/JobOfferStatusPill";
import ZoneList from "../../../../components/badges/ZoneList";
import LanguageList from "../../../../components/badges/LanguageList";
import {useTranslation} from "react-i18next";
import VisaList from "../../../../components/badges/VisaList";
import PostHeader from "../../../../components/PostHeader";
import Description from "../../../../components/Description";
import "./EmployeeDetailView.css"; // Include the CSS file
import {Text} from "@mantine/core";
import useIsOwn from "src/hooks/useIsOwn";
import CountryList from "../../../../components/badges/CountryList";
import EmployeeDetailHeader from "../../../components/EmployeeDetailHeader";

interface Props {
    employee: Employee;
}

export default function EmployeeDetailView({employee}: Props) {
    const isOwn = useIsOwn(employee);
    const {t} = useTranslation();

    if (!employee) {
        return <NotFound label={t("employee")}/>;
    }

    const {
        id,
        languages,
        fields,
        zones,
        desc,
        visa,
        skills = [],
        certifications,
        training,
        residentialCity = "N/A",
        residentialNation = "N/A",
    } = employee;

    return (
        <>
            <div className="employee-detail">

                <EmployeeDetailHeader post={employee} isOwn={isOwn}/>

                <EmployeeRow title={t("description") + ":"}>
                    <Description text={desc}/>
                </EmployeeRow>

                <EmployeeRow title={t("languages") + ":"}>
                    <LanguageList languages={languages}/>
                </EmployeeRow>

                <EmployeeRow title={t("info_place_of_work") + ":"}>
                    <ZoneList zones={zones} naked/>
                </EmployeeRow>

                <EmployeeRow title={t("residence") + ":"}>
                    <Text mr="md">{residentialCity}</Text>
                    <CountryList countries={[residentialNation]} big/>
                </EmployeeRow>

                <EmployeeRow title={t("info_fields") + ":"}>
                    <FieldList list={fields}/>
                </EmployeeRow>

                <EmployeeRow title={t("info_skills") + ":"}>
                    <SkillSelect values={skills}/>
                </EmployeeRow>

                <EmployeeRow title={t("info_visa_access") + ":"}>
                    <VisaList list={visa} naked/>
                </EmployeeRow>

                {!_isEmpty(certifications) && (
                    <EmployeeRow title={t("certifications") + ":"}>{certifications.join(", ")}</EmployeeRow>
                )}

                {!_isEmpty(training) && (
                    <EmployeeRow title={t("training") + ":"}>{training.join(", ")}</EmployeeRow>
                )}

                <Actions
                    actions={[
                        {
                            label: "back",
                            to: "/app/section/employees",
                            variant: "default",
                        },
                        isOwn
                            ? {
                                label: "edit_employee",
                                to: `/app/edit/employee/${id}`,
                                variant: "default",
                            }
                            : {
                                label: "submit_job",
                                to: `/app/add/proposal/${id}`,
                            },
                    ]}
                />
            </div>
        </>
    );
}
