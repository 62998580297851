export class Url {
    private url: URL;

    constructor(url?: string) {
        // If no URL is provided, use the current browser URL
        if (typeof window !== "undefined" && !url) {
            this.url = new URL(window.location.href);
        } else if (url) {
            this.url = new URL(url);
        } else {
            throw new Error("No URL provided, and window is undefined.");
        }
    }

    getFullUrl(): string {
        return this.url.href;
    }

    getProtocol(): string {
        return this.url.protocol;
    }

    getHost(): string {
        return this.url.hostname;
    }

    getPath(): string {
        return this.url.pathname;
    }

    getQueryString(): string {
        return this.url.search;
    }

    getQueryParams(): Record<string, string> {
        const params: Record<string, string> = {};
        this.url.searchParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }

    setQueryParam(key: string, value: string): void {
        this.url.searchParams.set(key, value);
    }

    removeQueryParam(key: string): void {
        this.url.searchParams.delete(key);
    }

    clearQueryParams(): void {
        this.url.search = "";
    }

    setPath(path: string): void {
        this.url.pathname = path;
    }

    toString(): string {
        return this.url.toString();
    }

    static analyze(url: string): Record<string, any> {
        const parsedUrl = new URL(url);
        return {
            fullUrl: parsedUrl.href,
            protocol: parsedUrl.protocol,
            host: parsedUrl.hostname,
            path: parsedUrl.pathname,
            queryString: parsedUrl.search,
            queryParams: Array.from(parsedUrl.searchParams.entries()).reduce(
                (obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                },
                {} as Record<string, string>
            ),
        };
    }

    queryParamIs(param: string, value: string): boolean {
        return this.url.searchParams.get(param) === value;
    }

    queryContains(substring: string): boolean {
        return this.url.search.includes(substring);
    }

    static fromBrowser(): Url {
        if (typeof window === "undefined") {
            throw new Error("Cannot access the browser URL in a non-browser environment.");
        }
        return new Url(window.location.href);
    }
}
/*
const myUrl = new Url("https://example.com/path?param1=value1&param2=value2");

console.log(myUrl.getFullUrl()); // Full URL
console.log(myUrl.getQueryParams()); // { param1: "value1", param2: "value2" }
myUrl.setQueryParam("param3", "value3"); // Add new query param
console.log(myUrl.toString()); // Updated URL with param3
myUrl.removeQueryParam("param1"); // Remove a query param
console.log(myUrl.toString()); // Updated URL without param1
*/