import { useParams } from "react-router-dom";
import EmployeeView from "src/components/view/Employee.view";
import Loader from "src/components/Loader";
import Employee from "src/types/Employee.type";
import { useGET } from "src/hooks/useApi";
import {Url} from "../../../lib/http/Url";
import EmployeeDetailView from "../../../entity/Employee/view/detail/EmployeeDetailView";
import Config from "../../../classes/application/Config";

export default function ViewEmployeePage() {
  const params = useParams();
  const url = `res/employee/${params.id}`;
  const { data, isLoading, error } = useGET<Employee>(url);


  let burl = Url.fromBrowser();
  let mode = "original";

  if (burl.queryParamIs("mode", "new")) {
    mode = "new";
  }
  let cfg = Config.getInstance();
  if(cfg.hasPublicAreaEnabled()) {
    mode = "new";
  }

  if (burl.queryParamIs("mode", "old")) {
    mode = "old";
  }

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      {mode === "new" ? (
          <EmployeeDetailView employee={data} />
      ) : (
          <EmployeeView employee={data} />
      )}


    </Loader>
  );
}
