import { IconHeartHandshake, IconMailbox, IconSend } from "@tabler/icons-react";
import ListJobHunting from "src/pages/data/list/ListJobHunting";
import ListMatches from "src/pages/data/list/ListMatches";
import ListTalentHunting from "src/pages/data/list/ListTalentHunting";

const navigationItems = [
  {
    title: "matches",
    label: "matches_sub",
    Icon: <IconHeartHandshake />,
    id: "matches",
    content: <ListMatches />,
  },
  {
    title: "talent_hunting",
    label: "talent_hunting_sub",
    Icon: <IconMailbox />,
    id: "talent_hunting",
    content: <ListTalentHunting />,
  },
  {
    title: "job_hunting",
    label: "job_hunting_sub",
    Icon: <IconSend />,
    id: "job_hunting",
    content: <ListJobHunting />,
  },
];

export default navigationItems;
