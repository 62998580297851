import { useMediaQuery } from "@mantine/hooks";

const useIsMobile = () => {
  const isSmallScreen = useMediaQuery("(max-width: 450px)");

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  let is_mobile =  isSmallScreen && isMobileDevice();
  return is_mobile;
};

export default useIsMobile;
