import Actions from "src/components/Actions";
import SearchJobs from "src/components/filters/SearchJobs";

export default function SearchJob() {
    return (
        <>
            <Actions
                title={"find_jobs"}
                infoboxCode={"infobox_project_find"}
            />
            <SearchJobs/>
        </>
    );
}
