export class ArrayAccessor {
    protected _data: Record<string, any>;

    constructor(data: Record<string, any> = {}) {
        this._data = data;
    }

    public getData(): Record<string, any> {
        return this._data;
    }

    public getDataObject(): any {
        return this.getData().data;
    }

    setData(value: any): void {
        this._data = value;
    }


    get(key: string, defaultValue: any = null): any {
        return key in this._data ? this._data[key] : defaultValue;
    }

    set(key: string, value: any): void {
        this._data[key] = value;

    }

    has(key: string): boolean {
        return key in this._data;
    }

    remove(key: string): void {
        delete this._data[key];
    }

    public setArrVal(arrname: string, elem: any): void {
        if (!this.has(arrname) || !Array.isArray(this.get(arrname))) {
            this.set(arrname, []);
        }
        this._data[arrname].push(elem);
    }

    toJson(): Record<string, any> {
        return {...this._data};
    }
}