import _startsWith from "lodash/startsWith";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useUser from "./useUser";
import hasRealCompany from "src/utils/hasRealCompany";
import { useI81nTour } from "./useI18nTour";
import ReactGA from "react-ga4";

const useRegisterIfNeeded = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading } = useUser();
  const { email } = useAuth();
  const { setIsOpen } = useI81nTour();

  useEffect(() => {
    if (isLoading) {
      // do not move
      return;
    }

    const onRegisterPath = _startsWith(location.pathname, "/register");
    const onChatbotPath = _startsWith(location.pathname, "/chatbot");
    const noCompany = !hasRealCompany(user);

    // Allow /chatbot path without any redirection logic
    if (onChatbotPath) {
      return;
    }

    if (noCompany) {
      if (!onRegisterPath) {
        /**
         * @todo check this
         */
        setTimeout(() => {
          window.location.href = "/register";
        }, 1000);
        /*
        navigate("/register");
        */
      }
      return;
    }

    const onRootPath = location.pathname === "/";
    const onAppPath = _startsWith(location.pathname, "/app");

    if (!onAppPath || onRootPath) {
      if (onRegisterPath) {
        // registration complete
        ReactGA.event({
          category: "GzMMCLGd5YoZENGU8-Eq",
          action: "conversion",
          value: 1.0,
        });

        setIsOpen(true);
      }
      navigate("/app");
      return;
    }
  }, [isLoading, location.pathname, navigate, user, email, setIsOpen]);
};

export default useRegisterIfNeeded;
