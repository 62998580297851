import _isEmpty from "lodash/isEmpty";
import { Row } from "src/components/Row";
import Actions from "src/components/Actions";
import JobOffer from "../../../../types/JobOffer.type";
import NotFound from "../../../../components/NotFound";
import FieldList from "../../../../components/badges/FieldList";
import SkillSelect from "../../../../components/SkillSelect";
import { JobOfferStatus as JobOfferStatusType } from "src/types/JobOfferStatus.type";
import JobOfferStatusPill from "../../../../components/JobOfferStatusPill";
import ZoneList from "../../../../components/badges/ZoneList";
import LanguageList from "../../../../components/badges/LanguageList";
import { useTranslation } from "react-i18next";
import VisaList from "../../../../components/badges/VisaList";
import useIsOwn from "../../../../hooks/useIsOwn";
import PostHeader from "../../../../components/PostHeader";
import DateRange from "../../../../components/badges/DateRange";
import Description from "../../../../components/Description";
import { JobOfferModel } from "../../../../models/JobOfferModel";
import "./JobOfferDetailView.css"; // Include the CSS file

interface Props {
    jobOffer: JobOffer;
}

export default function JobOfferDetailView({ jobOffer }: Props) {
    const isOwn = useIsOwn(jobOffer);
    const { t } = useTranslation();

    if (!jobOffer) {
        return <NotFound label="job_offer" />;
    }

    const {
        id,
        dateFrom,
        zones,
        desc,
        duration,
        languages,
        level,
        skills,
        certifications,
        fields,
        training,
        status,
        visa,
    } = jobOffer;

    let joboffer = new JobOfferModel();
    joboffer.setData(jobOffer);

    return (
        <div className="job-offer-detail">
            <PostHeader post={jobOffer} isOwn={isOwn} />

            {/* Two-column layout for job details */}
            <div className="job-details-grid">
                <div className="column">
                    <Row title={t("description") + ":"}>
                        <Description text={desc} />
                    </Row>
                    <Row title={t("status") + ":"}>
                        <JobOfferStatusPill status={status} />
                    </Row>
                    <Row title={t("level") + ":"}>{t(level)}</Row>
                    <Row title={t("expected_duration") + ":"}>
                        {duration ? `${duration} ${t("days")}` : "N/D"}
                    </Row>
                    <Row title={t("from_date") + ":"}>
                        <DateRange from={dateFrom} naked />
                    </Row>
                </div>

                <div className="column">
                    <Row title={t("info_place_of_work") + ":"}>
                        <ZoneList zones={zones} naked />
                    </Row>
                    <Row title={t("languages") + ":"}>
                        <LanguageList languages={languages} />
                    </Row>
                    <Row title={t("info_fields") + ":"}>
                        <FieldList list={fields} />
                    </Row>
                    <Row title={t("info_skills") + ":"}>
                        <SkillSelect values={skills} />
                    </Row>
                    <Row title={t("info_visa_access") + ":"}>
                        <VisaList list={visa} />
                    </Row>
                </div>
            </div>

            {/* Optional rows */}
            {!_isEmpty(certifications) && (
                <Row title={t("certifications") + ":"}>
                    {certifications.join(", ")}
                </Row>
            )}

            {!_isEmpty(training) && (
                <Row title={t("training") + ":"}>{training.join(", ")}</Row>
            )}

            <Actions
                actions={[
                    {
                        label: "back",
                        to: "/app/section/jobs",
                        variant: "default",
                    },
                    isOwn
                        ? {
                            label: "edit",
                            to: `/app/edit/job/${id}`,
                        }
                        : {
                            label: "apply_now",
                            to: `/app/add/application/${id}`,
                            disabled: status !== JobOfferStatusType.open,
                            hasConfirm: false,
                            confirmTitle: "candidatura",
                            confirmMessage: "confermi candidatura?",
                        },
                ]}
            />
        </div>
    );
}
