import React, { useState } from 'react';
import Application from "../../classes/Application";

export default function AiCfg() {
    const cfg = Application.getInstance().getConfig();
    const [aicc, setAiCc] = useState(cfg.getAi());


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //      alert(e.target.value);
         setAiCc(e.target.value);
    };

    const handleSave = () => {
        if (aicc) {
            cfg.storeAi(aicc);
            alert("now code is "+aicc);

        } else {
        }
    };

    const handleReset = () => {
       // setApiUrl(cfg.getApiUrl());
    };

    return (
        <div>
            <h2>Ai</h2>
            <input
                style={{ width: '300px' }}
                type="password"

                value={aicc}
                onChange={handleChange}
                placeholder="secret"
            />
            <button onClick={handleSave}>Save</button>
            <div>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
}
