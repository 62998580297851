import _isDate from "lodash/isDate";
import {formatDistance} from "date-fns";
import enUS from "date-fns/locale/en-US"; // Default import for English locale
import it from "date-fns/locale/it";
import Config from "../classes/application/Config"; // Default import for Italian locale

// Function to select locale
const getLocale = (locale: string) => {
    switch (locale) {
        case "it":
            return it;
        case "en":
        default:
            return enUS;
    }
};

export const formatDate = (
    date: number | string | Date | undefined | null,
    withTime?: boolean
): string => {
    if (!date) {
        return "";
    }
    const d = _isDate(date) ? date : new Date(date);

    return withTime ? d.toLocaleString() : d.toLocaleDateString();
};

interface Props2 {
    from?: string;
    to?: string | null;
    locale?: string | null; // Add locale parameter
}

export const getDistanceFromDate = ({from, to = null, locale = "undef"}: Props2) => {

    if (locale === "undef" || locale === null) {
        let cfg = Config.getInstance();
        locale = cfg.getLanguage();
    }

    if (!from) {
        return "";
    }

    if (locale === null) {
        locale = "en";
    }
    const fromDate = new Date(from);
    const toDate = to ? new Date(to) : new Date();

    // Use the selected locale
    const selectedLocale = getLocale(locale);

    return formatDistance(fromDate, toDate, {addSuffix: true, locale: selectedLocale});
};
