import _map from "lodash/map";
import _find from "lodash/find";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _delay from "lodash/delay";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import {Notifications, notifications} from "@mantine/notifications";
import {ReactNode, createContext, useEffect, useMemo, useState} from "react";
import useApi, {useGET} from "src/hooks/useApi";
import Notification, {NotificationType} from "src/types/Notification.type";
import {useSWRConfig} from "swr";
import {
    showNotificationModal,
    showNotificationPopup,
} from "src/utils/showNotification";
import useIsMobile from "src/hooks/useIsMobile";

const REFRESH_DELAY = 500;

interface INotificationProvider {
    notifications: Notification[];
    isLoading: boolean;
    error?: Error;
    markAsRead: (id: string) => void;
    getById: (id?: string) => Notification | undefined;
    count: number;
    ready: boolean;
    setReady: (value: boolean) => void;
}

export const NotificationContext = createContext<INotificationProvider>({
    notifications: [],
    isLoading: false,
    error: undefined,
    markAsRead: () => {
    },
    getById: () => {
        return undefined;
    },
    count: 0,
    ready: false,
    setReady: () => {
    },
});

export function NotificationProvider({children}: { children: ReactNode }) {
    const [ready, setReady] = useState(false);
    const url = `res/notification`;
    const API = useApi();
    const isMobile = useIsMobile();
    const {mutate} = useSWRConfig();
    const {data, isLoading, error} = useGET<Notification[]>(url);

    // clean notifications queue on ready state change
    useEffect(() => {
        notifications.clean();
        notifications.cleanQueue();
    }, [ready]);

    const value = useMemo<INotificationProvider>(() => {
        // mutate the state for each notification read
        const markAsRead = (id: string) => {
            API.PUT(`res/notification/${id}`, {}, {silent: true});
            _delay(() => mutate(url), REFRESH_DELAY);
        };

        const getById = (id?: string) => {
            if (!id) {
                return undefined;
            }
            return _find(data, {id});
        };
        const unreadNotifications = _filter(data, (n) => !n.read && !n.shown);
        const list = _reverse(_sortBy(data, "meta_created_at"));
        const count = _size(unreadNotifications);

        return {
            notifications: list,
            isLoading,
            error,
            markAsRead,
            getById,
            count,
            ready,
            setReady,
        };
    }, [data, isLoading, error, mutate, API, ready, url]);

    // show popup for each unread notification
    useEffect(() => {
        if (isLoading || error) {
            return;
        }
        const unreadNotifications = _filter(data, (n) => !n.read && !n.shown);
        const list = _slice(unreadNotifications, 0, 5);

        _map(list, (n: Notification) => {
            n.shown = true;
            const onCloseModal = () => {
                value.markAsRead(n.id);
            };
            const onClosePopup = () => {
                value.markAsRead(n.id);
                // navigate(n.link ?? '/app/notifications');
                // NOTE cannot use useNavigate here because we're outside RouterProvider
                const dest = n.link || `/app/notifications/${n.id}`;
                console.log({dest});
                window.history.pushState({}, "Notifications", dest);
                window.location.reload();
            };
            n.type === NotificationType.modal
                ? showNotificationModal(n, onCloseModal)
                : showNotificationPopup(n, onClosePopup);
        });
    }, [data, isLoading, error, value]);

    return (
        <NotificationContext.Provider value={value}>
            <Notifications
                zIndex={1000}
                limit={5}
                style={{
                    position: "fixed",
                    top: isMobile ? 140 : 90,
                    right: 10,
                }}
            />
            {children}
        </NotificationContext.Provider>
    );
}
