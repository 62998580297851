import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import JobOfferView from "src/components/view/Job.view";
import JobOfferDetailView from "../../../entity/JobOffer/view/detail/JobOfferDetailView"; // Import the new component
import { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";
import { Url } from "../../../lib/http/Url";
import Config from "../../../classes/application/Config";

export default function ViewJobPage() {
  const params = useParams();

  const url = `res/job/${params.id}`;

  const { data, isLoading, error } = useGET<JobOffer>(url);

  let burl = Url.fromBrowser();
  let mode = "original";


  if (burl.queryParamIs("mode", "new")) {
    mode = "new";
  }
  let cfg = Config.getInstance();
  if(cfg.hasPublicAreaEnabled()) {
    mode = "new";
  }

  if (burl.queryParamIs("mode", "old")) {
    mode = "old";
  }



  return (
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        {mode === "new" ? (
            <JobOfferDetailView jobOffer={data} />
        ) : (
            <JobOfferView jobOffer={data} />
        )}
      </Loader>
  );
}
