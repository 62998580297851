import {ApiServiceXmlHttp} from "./Apiservice/ApiServiceXmlHttp.js";
import {ApiServiceAxios} from "./Apiservice/ApiServiceAxios.js";
import {ApiService} from "./ApiService.js";
import Config from "../../classes/application/Config.js";

export class ApiServices {
    protected static _instance: ApiService | null = null;

    public static _mode: string = "xmlhttp"


    /**
     * @return ApiServiceXmlHttp
     * @param apiUrl
     */
    public static getInstance(apiUrl?: string): ApiService {


        let cfg = Config.getInstance();
        if (!apiUrl) {
            apiUrl = cfg.getApiUrl();
        }
        if (!apiUrl) {
            apiUrl = ""

        }

        if (ApiServices._mode === "xmlhttp") {
            ApiServices._instance = new ApiServiceXmlHttp(apiUrl);
        } else {
            ApiServices._instance = new ApiServiceAxios(apiUrl);
        }

        /*
        if (!ApiService._instance) {
            throw new Error("Subclasses must be instantiated with a specific implementation.");
        }
        */
        return ApiServices._instance;
    }


}
